.site-loader{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-play-button-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
}

.map-play-button-container > button{
    border: 0px solid black;
    background-color: #F35624;
    color: #FFFFFF;
    height: 40px;
    min-width: 40px;
    border-left: 1px solid #f1f1f1;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.padded-wrapper {
    padding: 0 35px;
}

a{
    text-decoration: none;
    
}

.location-search-input{
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    background: transparent;
    padding: 11px;
    font-weight: 600;
    margin: 0px;
    border: 1px solid rgba(0,0,0,0.33);
    border-radius: 4px;
    width: 100%;
    margin-bottom: 30px;
}

.autocomplete-dropdown-container{
    position: relative;
    height: auto;
    z-index: 999;
    margin-top: -20px;
}

.field-control{
    position: relative;
}

.gmnoprint > div > button[title="Stop drawing"],
.gmnoprint > div > button[title="Draw a shape"],
.gmnoprint > div > button[title="Draw a rectangle"],
.gmnoprint > div > button[title="Draw a circle"]{
  height: 40px;
  width: 40px;
  text-align: center !important;
}